
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import Multiselect from "@vueform/multiselect";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import { useStore } from "vuex";

interface ComponentData {
  submitButtonLoading: boolean;
  countries: MultiselectOptions[] | null;
  states: MultiselectOptions[] | null;
  selectedCountry: number | null;
  selectedState?: number | null;
  updatedAddress: Record<string, unknown>;
  isUpdateLabel: boolean;
}

export default defineComponent({
  name: "AddressUpdateModal",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    ModalComponent,
    Multiselect,
  },

  props: {
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    isEditableLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["updated", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      countries: null,
      states: null,
      updatedAddress: {},
      selectedCountry: null,
      selectedState: null,
      isUpdateLabel: props.isEditableLabel !== false,
    });

    const modal = ref<typeof ModalComponent | null>(null);
    const roles = computed(() => {
      return useStore().getters.currentUser.roles;
    });

    const { setValues, handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      let action = "";
      if (state.isUpdateLabel === true) {
        action = "label";
      }

      const requestBody = {
        ...values,
        customer_id: values.customer_id,
        roles: props.isEditableLabel === true ? roles.value : [],
        action: action,
        country_id: state.selectedCountry,
        state_id: state.selectedState,
      };

      state.submitButtonLoading = true;
      setFieldError("country_id", undefined);
      ApiService.put(`address/` + props.address.id.toString(), requestBody)
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Address updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const loadData = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries`)
          .then(({ data }) => {
            state.countries = setOptionsValues(data.countries);

            ApiService.get(`address/countries/` + countryId + `/states`)
              .then(({ data }) => {
                if (data.states.length > 0) {
                  state.states = setOptionsValues(data.states);
                } else {
                  state.states = null;
                  state.selectedState = null;
                }
                return resolve(true);
              })
              .catch(reject);
          })
          .catch(reject);
      });

    const countryChanged = (value) => {
      loadData(value as number).then(() => {
        if (state.states != null) {
          state.selectedState = state.states[0].value;
        }
      });
    };

    onMounted(() => {
      loadData(props.address.country.id).then(() => {
        setValues(props.address);
        state.selectedCountry = props.address.country.id;
        state.selectedState = props.address.state.id;
      });
    });

    return {
      props,
      state,
      roles,
      updateAddress,
      countryChanged,
      modal,
    };
  },
});
