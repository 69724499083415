
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";

interface ComponentData {
  countries: MultiselectOptions[] | null;
  languages: MultiselectOptions[] | null;
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "CustomerUpdateModal",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    ModalComponent,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      countries: null,
      languages: null,
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { setValues, handleSubmit, setFieldError } = useForm();

    const updateCustomer = handleSubmit((values) => {
      state.submitButtonLoading = true;
      ApiService.update(`customer`, String(props.customer.id), values)
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Customer updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const getCountries = (): void => {
      ApiService.get(`address/countries`)
        .then(({ data }) => {
          state.countries = setOptionsValues(data.countries);
          getLanguages();
        })
        .catch((error) => Notify.apiError(error));
    };

    const getLanguages = (): void => {
      ApiService.get(`languages/`)
        .then(({ data }) => {
          state.languages = setOptionsValues(data.languages);
          setValues(props.customer);
        })
        .catch((error) => Notify.apiError(error));
    };

    onMounted(() => {
      getCountries();
    });

    return {
      props,
      state,
      updateCustomer,
      modal,
    };
  },
});
