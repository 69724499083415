<template>
  <modal-component
    id="address-update-modal"
    ref="modal"
    size="large"
    @hide="$emit('hide')"
  >
    <template #title>
      Update address # {{ address.id }}
      <span
        v-if="address.parent_address_id !== null"
        class="badge badge-pill badge-success"
      >
        Label
      </span>
    </template>
    <template #default>
      <form autocomplete="off" @submit="updateAddress">
        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="firstname" class="form-label"> First Name </label>
          </div>
          <div class="col-10">
            <Field
              id="firstname"
              type="text"
              class="form-control form-control-solid"
              name="firstname"
              placeholder="Firstname"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="firstname" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="lastname" class="form-label"> Last Name </label>
          </div>
          <div class="col-10">
            <Field
              id="lastname"
              type="text"
              class="form-control form-control-solid"
              name="lastname"
              placeholder="Lastname"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="lastname" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="address1" class="form-label"> Address </label>
          </div>
          <div class="col-10">
            <Field
              id="address1"
              type="text"
              class="form-control form-control-solid"
              name="address1"
              placeholder="Address 1"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="address1" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="address2" class="form-label"> Address (2) </label>
          </div>
          <div class="col-10">
            <Field
              id="address2"
              type="text"
              class="form-control form-control-solid"
              name="address2"
              placeholder="Address 2"
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="address2" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="zip" class="form-label"> Zip/Postal Code </label>
          </div>
          <div class="col-10">
            <Field
              id="zip"
              type="text"
              class="form-control form-control-solid"
              name="zip"
              placeholder="Zip"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="zip" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="city" class="form-label"> City </label>
          </div>
          <div class="col-10">
            <Field
              id="city"
              type="text"
              class="form-control form-control-solid"
              name="city"
              placeholder="City"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="city" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="state.countries" id="country_container">
          <div class="row mb-2">
            <div class="col-2 m-auto">
              <label for="country_id" class="form-label"> Country </label>
            </div>
            <div class="col-10">
              <Multiselect
                id="country_id"
                v-model="state.selectedCountry"
                :options="state.countries"
                :searchable="true"
                name="country_id"
                @change="countryChanged"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="country_id"></ErrorMessage>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="state_container">
          <div class="row mb-2">
            <div class="col-2 m-auto">
              <label for="state_id" class="form-label"> State </label>
            </div>
            <div class="col-10">
              <div class="col">
                <Multiselect
                  id="state_id"
                  v-model="state.selectedState"
                  name="state_id"
                  :options="state.states"
                  :searchable="true"
                  :disabled="state.states == null"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="state"></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="phone" class="form-label"> Phone </label>
          </div>
          <div class="col-10">
            <Field
              id="phone"
              type="text"
              class="form-control form-control-solid"
              name="phone"
              placeholder="Phone"
              required
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="phone" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-2 m-auto">
            <label for="additional_information" class="form-label">
              Additional information
            </label>
          </div>
          <div class="col-10">
            <Field
              id="additional_information"
              type="text"
              class="form-control form-control-solid"
              name="additional_information"
              placeholder="Additional information"
              autocomplete="nope"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="additional_information" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="props.isEditableLabel" class="row mb-2">
          <div class="col d-flex align-items-center">
            <div
              v-if="
                roles.length > 0 &&
                roles.includes('PACKING') &&
                address.parent_address_id === null
              "
            >
              <em>Changes will be applied to Label address</em>
            </div>
            <div v-else>
              <div class="form-check form-check-custom form-check-solid">
                <label class="form-check-label" for="isUpdateLabel">
                  <input
                    id="isUpdateLabel"
                    v-model="state.isUpdateLabel"
                    class="form-check-input"
                    type="checkbox"
                    name="isUpdateLabel"
                  />
                  Update Label address
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <form-submit-button :loading="state.submitButtonLoading">
            Update address
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import Multiselect from "@vueform/multiselect";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import { useStore } from "vuex";

interface ComponentData {
  submitButtonLoading: boolean;
  countries: MultiselectOptions[] | null;
  states: MultiselectOptions[] | null;
  selectedCountry: number | null;
  selectedState?: number | null;
  updatedAddress: Record<string, unknown>;
  isUpdateLabel: boolean;
}

export default defineComponent({
  name: "AddressUpdateModal",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    ModalComponent,
    Multiselect,
  },

  props: {
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    isEditableLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["updated", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      countries: null,
      states: null,
      updatedAddress: {},
      selectedCountry: null,
      selectedState: null,
      isUpdateLabel: props.isEditableLabel !== false,
    });

    const modal = ref<typeof ModalComponent | null>(null);
    const roles = computed(() => {
      return useStore().getters.currentUser.roles;
    });

    const { setValues, handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      let action = "";
      if (state.isUpdateLabel === true) {
        action = "label";
      }

      const requestBody = {
        ...values,
        customer_id: values.customer_id,
        roles: props.isEditableLabel === true ? roles.value : [],
        action: action,
        country_id: state.selectedCountry,
        state_id: state.selectedState,
      };

      state.submitButtonLoading = true;
      setFieldError("country_id", undefined);
      ApiService.put(`address/` + props.address.id.toString(), requestBody)
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Address updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const loadData = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries`)
          .then(({ data }) => {
            state.countries = setOptionsValues(data.countries);

            ApiService.get(`address/countries/` + countryId + `/states`)
              .then(({ data }) => {
                if (data.states.length > 0) {
                  state.states = setOptionsValues(data.states);
                } else {
                  state.states = null;
                  state.selectedState = null;
                }
                return resolve(true);
              })
              .catch(reject);
          })
          .catch(reject);
      });

    const countryChanged = (value) => {
      loadData(value as number).then(() => {
        if (state.states != null) {
          state.selectedState = state.states[0].value;
        }
      });
    };

    onMounted(() => {
      loadData(props.address.country.id).then(() => {
        setValues(props.address);
        state.selectedCountry = props.address.country.id;
        state.selectedState = props.address.state.id;
      });
    });

    return {
      props,
      state,
      roles,
      updateAddress,
      countryChanged,
      modal,
    };
  },
});
</script>

<style scoped></style>
